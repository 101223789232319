






























































































































































































import Vue, { VueConstructor } from 'vue';
import { ApolloError } from 'apollo-client';
import Spinner from '@/components/Spinner.vue';
import {
  COLLECTIONS_QUERY,
  CURRENT_COLLECTIONS,
  SLOT_QUERY,
} from '../queries/query';
import { Table, TableColumn } from 'element-ui';
import { CollectionType, CollectionSlot } from '../model/slot';
import { apolloClient } from '@/apolloClient';
import {
  Collection,
  CollectionConnection,
  CollectionVisibility,
} from '../model/collection';
import draggable from 'vuedraggable';
import { ClientPlatform, CommonState } from '@frientrip/domain';
import { CollectionService } from '../service/CollectionService';
import { getDomainName } from '@/env';

type ViewCollection = Omit<Collection, 'status'> & { status: boolean };

const convertStatusToBoolean = (status: CommonState): boolean => {
  return status === CommonState.ACTIVE ? true : false;
};
const collectionService = new CollectionService(apolloClient);

export default (
  Vue as VueConstructor<Vue & { $refs: { [key: string]: HTMLElement } }>
).extend({
  name: 'CollectionThemeList',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Spinner,
    draggable,
  },
  data() {
    return {
      slotId: this.$route.params.slotId,
      collections: [] as ViewCollection[],
      loading: 0,
      isAnyChanged: false,
      // vue 2 not support set, map
      // https://stackoverflow.com/questions/37130105/does-vue-support-reactivity-on-map-and-set-data-types
      // https://github.com/vuejs/vue/issues/2410#issuecomment-318487855
      currentCollectionsMapChangeTracker: 1,
      currentCollectionsMap: new Map<
        string,
        {
          platform: ClientPlatform;
          onlyHome: boolean;
          collections: Collection[];
          loading: boolean;
        }
      >(),
      queryPlatforms: [
        ClientPlatform.AndroidApp,
        ClientPlatform.iOSApp,
        ClientPlatform.Web,
        ClientPlatform.Mobile,
      ] as ClientPlatform[],
      queryOnlyHome: [true, false] as boolean[],
      slot: { maximumCountOfCollection: 0 },
    };
  },
  computed: {
    queryParams(): {
      slotId: string;
      platform: ClientPlatform;
      onlyHome: boolean;
    }[] {
      const result: {
        slotId: string;
        platform: ClientPlatform;
        onlyHome: boolean;
      }[] = [];
      this.queryOnlyHome.forEach(onlyHome => {
        this.queryPlatforms.forEach(platform => {
          result.push({
            slotId: this.slotId,
            platform: platform,
            onlyHome: onlyHome,
          });
        });
      });
      return result;
    },
    maximumCountOfCollection(): number {
      const result = this.slot?.maximumCountOfCollection || 0;
      return result;
    },
    currentCollectionsMapAsList():
      | 0
      | [
          string,
          {
            platform: ClientPlatform;
            onlyHome: boolean;
            collections: Collection[];
            loading: boolean;
          }
        ][] {
      // will be delete
      return (
        this.currentCollectionsMapChangeTracker && [
          ...this.currentCollectionsMap,
        ]
      );
    },
  },
  activated: async function () {
    await this.$apollo.queries.collections.refetch();
    await this.refetchCurrents();
  },
  created: async function () {
    await this.refetchCurrents();
  },
  methods: {
    openThemeCollectionPage(id: number): void {
      // https://next.frip.co.kr/collections/240
      window.open(`${getDomainName()}/collections/${id}`, '_blank');
    },
    isShowing(
      id: string,
      platform: ClientPlatform,
      onlyHome: boolean
    ): boolean {
      let result = false;
      const key = this.getCurrentCollectionsKeyByPlatformAndOnlyHome(
        platform,
        onlyHome
      );

      const currentCollection = this.currentCollectionsMap.get(key);
      if (currentCollection && currentCollection.collections.length) {
        result = currentCollection.collections.some(item => {
          return id === item.id;
        });
      }
      return result;
    },
    isCurrentCollectionLoading(): boolean {
      let result = false;
      if (this.currentCollectionsMapAsList != 0) {
        if (this.currentCollectionsMapAsList.length < 8) {
          result = true;
        }
        this.currentCollectionsMapAsList.forEach(([key, item]) => {
          if (item.loading) {
            result = true;
          }
        });
      } else {
        result = true;
      }
      return result;
    },
    getCurrentCollectionsKeyByPlatformAndOnlyHome(
      platform: ClientPlatform,
      onlyHome: boolean
    ) {
      return `${platform}_${onlyHome}`;
    },
    async refetchCurrents() {
      this.currentCollectionsMap.clear();
      this.currentCollectionsMapChangeTracker += 1;
      const queries = this.queryParams.map(param => {
        return apolloClient.query<{ collection: { currents: Collection[] } }>({
          query: CURRENT_COLLECTIONS,
          variables: param,
        });
      });
      // console.log(queries);
      const results = await Promise.all(queries);
      results.forEach((result, index) => {
        const { platform, onlyHome } = this.queryParams[index];
        this.currentCollectionsMap.set(
          this.getCurrentCollectionsKeyByPlatformAndOnlyHome(
            platform,
            onlyHome
          ),
          {
            platform: platform,
            onlyHome: onlyHome,
            collections: result.data.collection.currents,
            loading: result.loading,
          }
        );
        this.currentCollectionsMapChangeTracker += 1;
      });
      console.log(this.currentCollectionsMap.values());
    },
    getLocalDateTime(inputDateTime: number | null): string {
      let result = '';
      if (inputDateTime !== null) {
        result = this.$moment(inputDateTime).format('YYYY-MM-DD A HH:mm:ss ');
      }
      return result;
    },
    async updateVisibleStates(): Promise<void> {
      this.$modal.show(
        {
          title: '변경사항을 저장하시겠습니까?',
          type: 'warning',
          showCancelButton: true,
        },
        async () => {
          try {
            const params: CollectionVisibility[] = this.collections.map(
              ({ id, status, isShownAtHome, isRandomOrder }) => ({
                id,
                home: isShownAtHome,
                listing: status,
                randomOrder: isRandomOrder,
              })
            );
            await collectionService.updateCollectionVisibleStates(
              this.slotId,
              params
            );
            this.$apollo.queries.collections.refetch();
            await this.refetchCurrents();
            this.isAnyChanged = false;
            this.showSuccessMessage('변경 되었습니다.');
          } catch (err) {
            console.error(err);
            this.showErrorMessage(err.message);
          }
        }
      );
    },
    isAnyChangedType(): string {
      return this.isAnyChanged ? 'primary' : 'secondary';
    },
    updatedCollectionSortEvent(): void {
      let seqNo = 1;
      this.isAnyChanged = true;
      this.collections.forEach((collection: ViewCollection) => {
        if (collection.status || collection.isShownAtHome) {
          collection.seqNo = seqNo;
          seqNo += 1;
        } else {
          collection.seqNo = 0;
        }
      });
    },
    updateStatus(): void {
      this.updatedCollectionSortEvent();
      this.isAnyChanged = true;
    },
    updateIsRandomOrder(): void {
      this.isAnyChanged = true;
    },
    getRowClass(row: ViewCollection): string {
      let result = 'gray';
      if (row.status === true || row.isShownAtHome === true) {
        result = 'white';
      }
      return result;
    },
    getTitleType(): string {
      const collectionType = this.$attrs.CollectionType as CollectionType;
      return collectionType === CollectionType.PRODUCT ? '상품' : '테마';
    },
    showErrorMessage(message: string): void {
      this.$notify({
        duration: 5,
        title: '실패',
        message: message.replace('GraphQL error:', ''),
        type: 'warning',
      });
    },
    showSuccessMessage(message: string): void {
      this.$notify({
        duration: 5,
        title: '성공',
        message: message,
        type: 'success',
      });
    },
  },
  apollo: {
    collections: {
      query: COLLECTIONS_QUERY,
      variables(): { slotId: string; page: number; pageSize: number } {
        return { slotId: this.slotId, page: 1, pageSize: 1000 };
      },
      error(error: ApolloError): void {
        console.error(error);
        this.showErrorMessage(error.message);
      },
      update(data: {
        collection: { collections: CollectionConnection };
      }): ViewCollection[] {
        const result = data.collection.collections.edges.map(
          ({ node: { status, ...rest } }) => ({
            status: convertStatusToBoolean(status),
            ...rest,
          })
        );
        return result;
      },
    },
    slot: {
      query: SLOT_QUERY,
      variables(): { id: string } {
        return {
          id: this.slotId,
        };
      },
      error(error: ApolloError): void {
        console.error(error);
        this.showErrorMessage(error.message);
      },
      update: (data: {
        collection: { slot: CollectionSlot };
      }): Omit<CollectionSlot, 'collections' | 'countOfVisibleCollections'> => {
        // query result must be deep copy = implicit caching
        return JSON.parse(JSON.stringify(data.collection.slot));
      },
    },
  },
});
