var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('content-card',{attrs:{"title":("홈 " + (this.getTitleType()) + " 기획전 관리"),"notification":("활성화 최대 갯수는 " + (this.maximumCountOfCollection || '') + "개입니다.")}},[(_vm.loading > 0)?_c('spinner'):_c('div',[_c('table',{staticClass:"table border"},[_c('thead',{staticClass:"thead-light"},[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("순서")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("아이디")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("타이틀")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("썸내일")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("홈 노출 예약")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("홈 노출 활성화")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("리스트 노출 예약")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("리스트 노출 활성화")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("순서 랜덤 노출")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("홈AOS")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("홈IOS")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("홈WEB")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("홈MOBILE_WEB")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("AOS")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("IOS")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("WEB")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("MOBILE_WEB")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("링크")])])]),_c('draggable',{attrs:{"tag":"tbody"},on:{"sort":_vm.updatedCollectionSortEvent},model:{value:(_vm.collections),callback:function ($$v) {_vm.collections=$$v},expression:"collections"}},_vm._l((_vm.collections),function(item,index){return _c('tr',{key:(index + " + '_' + " + (item.id)),class:_vm.getRowClass(item)},[_c('td',[_vm._v(" "+_vm._s(item.seqNo)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.id)+" ")]),_c('td',[_c('router-link',{attrs:{"to":{
                  name: 'CollectionThemeUpdate',
                  params: { slotId: item.slot.id, collectionId: item.id },
                }}},[_vm._v(" "+_vm._s(item.title)+" ")])],1),_c('td',[(item.content && item.content.thumbnail)?_c('b-img',{attrs:{"width":"100px","src":item.content.thumbnail}}):_c('div',[_vm._v("미리보기 없음")])],1),_c('td',[(item.homeShowStartAt || item.homeShowEndAt)?_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.bottom",value:({
                  title:
                    _vm.getLocalDateTime(item.homeShowStartAt) +
                    '~' +
                    _vm.getLocalDateTime(item.homeShowEndAt),
                }),expression:"{\n                  title:\n                    getLocalDateTime(item.homeShowStartAt) +\n                    '~' +\n                    getLocalDateTime(item.homeShowEndAt),\n                }",modifiers:{"bottom":true}}],staticClass:"ni ni-time-alarm float-right lg",attrs:{"name":"ni ni-time-alarm","size":"lg"}}):_vm._e()]),_c('td',[_c('base-switch',{attrs:{"shape":"pill","type":"frip-primary","on-text":"","off-text":""},on:{"input":function($event){return _vm.updateStatus()}},model:{value:(item.isShownAtHome),callback:function ($$v) {_vm.$set(item, "isShownAtHome", $$v)},expression:"item.isShownAtHome"}})],1),_c('td',[(item.listShowStartAt || item.listShowEndAt)?_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.bottom",value:({
                  title:
                    _vm.getLocalDateTime(item.listShowStartAt) +
                    '~' +
                    _vm.getLocalDateTime(item.listShowEndAt),
                }),expression:"{\n                  title:\n                    getLocalDateTime(item.listShowStartAt) +\n                    '~' +\n                    getLocalDateTime(item.listShowEndAt),\n                }",modifiers:{"bottom":true}}],staticClass:"ni ni-time-alarm float-right lg",attrs:{"name":"ni ni-time-alarm","size":"lg"}}):_vm._e()]),_c('td',[_c('base-switch',{attrs:{"shape":"pill","type":"frip-primary","on-text":"","off-text":""},on:{"input":function($event){return _vm.updateStatus()}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})],1),_c('td',[_c('base-switch',{attrs:{"shape":"pill","type":"frip-primary","on-text":"","off-text":""},on:{"input":function($event){return _vm.updateIsRandomOrder()}},model:{value:(item.isRandomOrder),callback:function ($$v) {_vm.$set(item, "isRandomOrder", $$v)},expression:"item.isRandomOrder"}})],1),_vm._l((_vm.queryParams),function(queryParam){return _c('td',{key:((queryParam.slotId) + "_" + (queryParam.platform) + "_" + (queryParam.onlyHome))},[(_vm.isCurrentCollectionLoading())?_c('spinner'):(
                  _vm.isShowing(item.id, queryParam.platform, queryParam.onlyHome)
                )?_c('frip-badge',{attrs:{"type":"primary","shape":"pill"}},[_vm._v("ON")]):_c('frip-badge',{staticClass:"off-badge",attrs:{"shape":"pill"}},[_vm._v("OFF")])],1)}),_c('td',[_c('frip-button',{attrs:{"type":"secondary","outline":""},on:{"click":function($event){return _vm.openThemeCollectionPage(item.id)}}},[_vm._v(" 이동 ")])],1)],2)}),0)],1)]),_c('b-card-footer',[_c('b-row',{staticClass:"float-right"},[_c('router-link',{attrs:{"to":{
            name: 'CollectionThemeSlotList',
          }}},[_c('frip-button',{staticClass:"mx-3",attrs:{"outline":"","type":"secondary"}},[_vm._v("뒤로가기")])],1),_c('frip-button',{staticClass:"mx-2",attrs:{"type":"default","outline":""}},[_vm._v("캐시 초기화")]),_c('frip-button',{staticClass:"mx-2",attrs:{"type":_vm.isAnyChangedType(),"disabled":!_vm.isAnyChanged},on:{"click":function($event){return _vm.updateVisibleStates()}}},[_vm._v("변경 사항 저장")]),_c('router-link',{attrs:{"to":{
            name: 'CollectionThemeCreate',
            params: { slotId: this.slotId },
          }}},[_c('frip-button',{attrs:{"type":"primary"}},[_vm._v("기획전 등록")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }